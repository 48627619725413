import { createApp } from 'vue'
import App from './App.vue'
import { createI18n } from 'vue-i18n'
import VueReCaptcha from 'vue-recaptcha-v3'


const messages = {
    en: {
        Navigation: {
            Home: 'Home',
            Product: 'Details',
            Contact: 'Contact'
        },
        Hero: {
            MainTitle: 'Pure Oregano Bliss',
            SubTitle: 'Nature\'s Essential Elixir',
            Tagline: 'Experience the Power of Natural Oregano Oil for a Healthier You',
            ButtonMain: 'Shop Now for',
            ButtonSub: 'Pure Wellness',
        },
        Product: {
            Info: 'The active compounds in oregano oil, such as carvacrol and thymol,contribute to its antimicrobial, anti-inflammatory, andantioxidant properties. These attributes make it a valuableaddition to holistic wellness practices, supporting various aspects of health.',
            IconPure: 'Organic and pure <br> oregano extract',
            IconAntiMicrobal: 'Potent antimicrobial <br> properties',
            IconImmune: ' Immune <br>system support',
            IconVersatile: 'Aromatherapy, <br>topical application etc.',
        },
        Contact: {
            FullName: 'Full Name',
            Email: 'Email',
            Message: 'Message',
            Submit: 'Submit',
            Success: 'Thank you for your message!',
            GetBack: ' We will get back to you as soon as possible.',
            PlaceHolderName: 'Enter your name',
            PlaceHolderEmail: 'Enter your email',
            PlaceHolderMessage: 'Enter your message'
        }
    },
    hu: {
        Navigation: {
            Home: 'Kezdőlap',
            Product: 'Részletek',
            Contact: 'Írj nekünk'
        },
        Hero: {
            MainTitle: 'Oregánó Olaj',
            SubTitle: 'Természet Ajándéka',
            Tagline: 'Ismerd meg a Természetes Oregánóolaj Energiáját Egészségedért',
            ButtonMain: 'Vásárlás a',
            ButtonSub: 'Tiszta Egészségért',
        },
        Product: {
            Info: 'Az oregánóolaj aktív alkotóelemei, mint például a karvakrol és tümol, valódi szupererővel rendelkeznek. Ezek a kis csodaszerek antimikrobiális, gyulladáscsökkentő és antioxidáns tulajdonságokkal járnak. Egyszerűen fogalmazva, nagy segítségünkre vannak az egészségünk és általános jó közérzetünk megőrzésében. Együtt járulnak hozzá ahhoz, hogy mindig a legjobb formánkat hozzuk.',
            IconPure: 'Bio és tiszta <br> oregánó kivonat',
            IconAntiMicrobal: 'Erős antimikrobiális <br> tulajdonságok',
            IconImmune: 'Immunrendszer <br> támogatás',
            IconVersatile: 'Aromaterápia, <br> masszázs, fürdő stb.',
        },
        Contact: {
            FullName: 'Teljes név',
            Email: 'Email',
            Message: 'Üzenet',
            Submit: 'Küldés',
            Success: 'Sikeres küldés',
            GetBack: 'Hamarosan jelentkezünk!',
            PlaceHolderName: 'Add meg a neved',
            PlaceHolderEmail: 'Add meg az email címed',
            PlaceHolderMessage: 'Írd meg az üzeneted'
        }
    }
}

const i18n = createI18n({
    locale: navigator.language.split('-')[0] || 'en', // set locale to browser's language
    messages, // set locale messages
})




// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'

// Make BootstrapVue available throughout your project
// Optionally install the BootstrapVue icon components plugin
createApp(App).use(i18n).mount('#app').use(VueReCaptcha, { siteKey: "6LfyNEQpAAAAAFT5RjH074W-SVq4SxMmzxuchmIV" });