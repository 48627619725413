<template>
  <div>
    <section
      class="container py-5 d-flex align-items-cente"
      style="margin-top: 100px"
    >
      <div class="row align-items-center">
        <div class="col-lg-6">
          <img
            src="../assets/img/hero-image.png"
            class="img-fluid"
            alt="hero-img"
          />
        </div>
        <div class="col-lg-6">
          <h1 class="hero-title">
            {{ $t("Hero.MainTitle") }}: <br />{{ $t("Hero.SubTitle") }}
          </h1>
          <p class="tagline">
            {{ $t("Hero.Tagline") }}
          </p>
          <a
            href="https://doterra.me/tiBGEo"
            target="_blank"
            class="callToAction"
          >
            {{ $t("Hero.ButtonMain") }} <br />
            {{ $t("Hero.ButtonSub") }}</a
          >
        </div>
      </div>
    </section>
    <main>
      <div class="wave" style="width: 100%">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            class="shape-fill"
          ></path>
        </svg>
      </div>
      <!-- Introduction Section -->
      <section id="more-info" class="container py-5 intro-section blue">
        <div class="row align-items-center">
          <div class="col-lg-6 offset-lg-6 col-sm-12">
            <p class="info">
              {{ $t("Product.Info") }}
            </p>
          </div>
        </div>
      </section>
      <div class="spacer layer1"></div>
      <!-- Features Section -->
      <section class="container py-5">
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <div class="flex features">
              <img src="../assets/img/1x/clear_drop.png" height="150" alt=" " />
              <div class="card-body">
                <h3 class="details-text" v-html="$t('Product.IconPure')"></h3>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="flex features">
              <img
                src="../assets/img/1x/antimicrobal.png"
                height="150"
                alt="Feature 2"
              />
              <div class="card-body">
                <h3
                  class="details-text"
                  v-html="$t('Product.IconAntiMicrobal')"
                ></h3>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="flex features">
              <img
                src="../assets/img/1x/Immune.png"
                height="150"
                alt="Feature 3"
              />
              <div class="card-body">
                <h3 class="details-text" v-html="$t('Product.IconImmune')"></h3>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="flex features">
              <img
                src="../assets/img/1x/versatile.png"
                height="150"
                alt="Feature 4"
              />
              <div class="card-body">
                <h3
                  class="details-text"
                  v-html="$t('Product.IconVersatile')"
                ></h3>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="contact-form"
        class="d-flex justify-content-center align-items-center vh-100"
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-6">
              <img :src="localeImage" class="img-fluid" alt="savings" />
            </div>
            <div class="col-lg-6" id="contactForm">
              <form
                ref="values"
                action="https://formsubmit.co/93a8657fe5b9122ba7eb13df4a9bdaa6 "
                method="POST"
                @submit.prevent="handleSubmit"
                v-if="!formSubmitted"
              >
                <div class="mb-3">
                  <label for="name" class="form-label">{{
                    $t("Contact.FullName")
                  }}</label>
                  <input
                    v-model="from_name"
                    name="fullName"
                    type="text"
                    class="form-control"
                    id="name"
                    :placeholder="$t('Contact.PlaceHolderName')"
                  />
                </div>
                <div class="mb-3">
                  <label for="email" class="form-label">{{
                    $t("Contact.Email")
                  }}</label>
                  <input
                    v-model="email"
                    name="email"
                    type="email"
                    class="form-control"
                    id="email"
                    :placeholder="$t('Contact.PlaceHolderEmail')"
                  />
                </div>
                <div class="mb-3">
                  <label for="message" class="form-label">{{
                    $t("Contact.Message")
                  }}</label>
                  <textarea
                    v-model="message"
                    name="message"
                    class="form-control"
                    id="message"
                    rows="3"
                    :placeholder="$t('Contact.PlaceHolderMessage')"
                  ></textarea>
                </div>
                <button
                  type="submit"
                  class="btn btn-primary btn-dark"
                  style="
                    width: 250px;
                    height: 50px;
                    margin: auto;
                    display: block;
                  "
                >
                  {{ $t("Contact.Submit") }}
                </button>
              </form>
              <div v-else>
                <h2 class="text-center">{{ $t("Contact.Success") }}</h2>
                <p class="text-center">
                  {{ $t("Contact.GetBack") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>
<script>
import emailjs from "emailjs-com";
export default {
  data() {
    return {
      from_name: "",
      email: "",
      message: "",
      formSubmitted: false,
      recaptchaVerified: false,
    };
  },
  computed: {
    localeImage() {
      const locale = this.$i18n.locale;
      return require(`../assets/img/contact_${locale}.png`);
    },
  },
  methods: {
    verifyCaptcha(response) {
      this.recaptchaResponse = response;
      this.recaptchaVerified = true;
    },
    async handleSubmit() {
      console.log("submitting form");
      const formData = new FormData();
      formData.append("from_name", this.fullName);
      formData.append("email", this.email);
      formData.append("message", this.message);
      console.log(formData);
      try {
        emailjs
          .sendForm(
            "service_0nyubou",
            "template_9r0hxx7",
            this.$refs.values,
            "40_05YTxBlvTH8k4T"
          )
          .then(
            (result) => {
              console.log(
                "You have successfully submitted your message",
                result.text
              );
              this.formSubmitted = true;
            },
            (error) => {
              console.log(
                "This form failed to submit, please kindly check your internet connection",
                error.text
              );
            }
          );
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
<style scoped>
#contactForm {
  background: white;
  color: var(--dark-blue-color);
  border-radius: 10%;
  box-shadow: #43b282 0px 0px 10px 0px;
}
.hero-title {
  font-family: "Kalnia";
  font-weight: 700;
  font-size: 3.5em;
  text-align: center;
  display: flex important!;
}

.tagline {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 2.5rem !important;
  text-align: center;
}

.callToAction {
  font-family: "Kalnia";
  font-weight: 400;
  font-size: 35px;
  align-items: center;
  display: flex;
  width: 350px;
  justify-content: center;
  text-decoration: none;
  margin: auto;
  border: 2px solid black;
  color: var(--dark-blue-color);
}

.callToAction:hover {
  background-color: var(--dark-blue-color);
  color: white;
  transition: 0.2s ease-in-out;
}

.info {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 2em;
}

main {
  background: linear-gradient(#43b282, #24224f);
  color: white;
}

.wave {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  z-index: 20;
}

.wave svg {
  position: relative;
  display: block;
  width: calc(103% + 1.3px);
  height: 262px;
}

.wave .shape-fill {
  fill: white;
}

.intro-section {
  margin-top: -2em;
}

.features {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 2em;
}

#contact-form {
  font-family: "Roboto", sans-serif;
  font-size: 2em;
  justify-content: center;
  background-color: linear-gradient(#24224f, #43b282);
}

#contact-us {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-family: "Roboto", sans-serif;
  font-size: 1.5em;
  justify-content: center;
  text-align: justify;
  padding: 2em;
  margin-bottom: 1em;
  color: var(--dark-blue-color);
}

.bg-nav-color {
  background-color: var(--dark-blue-color);
}

.details-text {
  font-family: "Roboto", sans-serif;
  font-size: 1.2em;
  margin-top: 0.5em;
  margin-bottom: 0.3em;
  font-weight: 300;
  text-align: center;
}
</style>
