<template>
  <div id="app">
    <Nav></Nav>
    <Details></Details>
    <Footer></Footer>
  </div>
</template>

<script>
import Details from "./components/Details.vue";
import Nav from "./components/Nav.vue";
import Footer from "./components/Footer.vue";
export default {
  name: "App",
  components: { Nav, Details, Footer },
};
</script>
<style>
:root {
  --green-color: #43b282;
  --dark-blue-color: #24224f;
  --light-blue-color: #1a82ae;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
