<template>
  <nav
    :class="{
      'bg-dark': isScrolled || isMobile,
      'bg-transparent': !isScrolled && !isMobile,
    }"
    id="mainNav"
    class="navbar navbar-expand-lg navbar-light fixed-top"
  >
    <div class="container">
      <a class="navbar-brand" href="#"
        ><img :src="scrollImage" width="150" alt=""
      /></a>
      <button
        class="navbar-toggler"
        type="button"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="showNav = !showNav"
        style="align-items: center"
      >
        <i style="color: white" class="fa fa-bars"></i>
      </button>
      <div
        class="collapse navbar-collapse justify-content-center align-items-center"
        :class="{ show: showNav }"
        id="navbarNav"
      >
        <ul class="navbar-nav ms-auto" style="margin-top: 0.2em">
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ 'text-white': isScrolled, 'text-dark': !isScrolled }"
              href="#"
              >{{ $t("Navigation.Home") }}</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              :class="{ 'text-white': isScrolled, 'text-dark': !isScrolled }"
              href="#more-info"
              >{{ $t("Navigation.Product") }}</a
            >
          </li>
          <li class="nav-item">
            <a
              :class="{ 'text-white': isScrolled, 'text-dark': !isScrolled }"
              class="nav-link"
              href="#contact-form"
              >{{ $t("Navigation.Contact") }}</a
            >
          </li>
          <select
            id="langSelect"
            v-model="selectedOption"
            @change="setLanguage"
          >
            <option
              v-for="option in options"
              :key="option.value"
              :value="option.value"
            >
              {{ option.text }}
            </option>
          </select>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  data() {
    return {
      selectedOption: this.$i18n.locale,
      options: [
        // This array holds the options for the dropdown
        { value: "en", text: "English" },
        { value: "hu", text: "Magyar" },
        // Add more options as needed
      ],
      showNav: false,
      isScrolled: false,
      isMobile: false,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
    this.handleResize(); // call it once at mount
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    scrollImage() {
      if (this.isScrolled) {
        return require("../assets/img/4x/300ppi/logo_white.png");
      } else {
        return require("../assets/img/4x/300ppi/logo.png");
      }
    },
  },
  methods: {
    handleScroll() {
      this.isScrolled = window.scrollY > 0;
    },
    handleResize() {
      this.isScrolled = window.innerWidth <= 768; // change 768 to your preferred breakpoint
    },
    setLanguage() {
      this.$i18n.locale = this.selectedOption;
    },
  },
};
</script>
<style scoped>
nav {
  font-family: "Roboto", sans-serif;
  font-size: 2em;
  font-weight: 400;
}

.nav-link {
  margin-right: 1.5em !important;
  width: 5em;
}

.text-white:hover {
  background-color: white !important;
  border: 1px solid black !important;
  color: var(--dark-blue-color) !important;
}

.text-dark {
  color: black !important;
}
.text-white {
  color: white !important;
}
.text-dark:hover {
  background-color: var(--dark-blue-color) !important;
  border: 1px solid black !important;
  color: white !important;
}
@media (max-width: 768px) {
  .nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
#langSelect {
  font-size: 0.6em;
  border: none;
  padding: 5px;
  color: var(--dark-blue-color);
}
</style>
